import { Phone, WhatsApp } from "@mui/icons-material";

import { Fab } from "@mui/material";

export const FloatingButtons = () => {
  const openWhatsApp = () => {
    const whatsappNumber = "+919981373343";
    const url = `https://wa.me/${whatsappNumber}`;
    window.open(url, "_blank");
  };

  const openPhoneCall = () => {
    const phoneNumber = "+919981373343";
    window.open(`tel:${phoneNumber}`);
  };
  return (
    <>
      <Fab
        variant="extended"
        aria-label="add"
        style={{
          position: "fixed",
          bottom: "20px",
          left: "20px",
          zIndex: "1000",
          backgroundColor: "#25D366",
        }}
        onClick={openWhatsApp}
      >
        <WhatsApp />
      </Fab>
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "1000",
        }}
        onClick={openPhoneCall}
      >
        <Phone />
      </Fab>
    </>
  );
};
