import React, { useRef, forwardRef } from "react";
import "./FileUpload.scss";
import { AttachFile, Cancel } from "@mui/icons-material";
import { UseControllerProps, useController } from "react-hook-form";

const allowedFileTypes = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "image/jpg",
  "text/plain",
  "video/mp4",
  "audio/mp3",
];

interface FileUploadProps {
  label: string;
  value: {
    base64: string;
    fileName: string;
    error?: string;
  };
  onChange: (value: {
    base64: string;
    fileName: string;
    error?: string;
  }) => void;
  onBlur: () => void;
  error?: string;
}

export const FileUpload: React.FC<FileUploadProps & UseControllerProps> =
  forwardRef((props, ref) => {
    const { label, onChange, onBlur, error } = props;
    const { field } = useController(props);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      const file = files[0];
      if (file) {
        if (!allowedFileTypes.includes(file.type)) {
          onChange({
            base64: "",
            fileName: "",
            error: "Unsupported file type. Please upload a valid file.",
          });
          return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          onChange({
            base64: reader.result as string,
            fileName: file.name,
          });
        };
        reader.readAsDataURL(file);
      }
    };

    const handleRemove = () => {
      field.onChange(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };

    const handleFileClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    return (
      <div className="file-upload">
        {label && <label className="text-input-label">{label}</label>}
        <input
          type="file"
          onChange={handleFileChange}
          onBlur={onBlur}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <div className="file-upload-container">
          <div className="file-upload-choose" onClick={handleFileClick}>
            <span className="file-upload-text">Choose file</span>
            <AttachFile className="file-upload-icon" />
          </div>
          {field.value?.fileName && (
            <div className="file-upload-file-name">
              {field.value.fileName}
              <Cancel
                className="file-upload-remove-icon"
                onClick={handleRemove}
              />
            </div>
          )}
        </div>
        {error && <div className="form-error">{error}</div>}
      </div>
    );
  });

// export const FileUpload: React.FC<FileUploadProps & UseControllerProps> = (
//   props
// ) => {
//   const { label, onChange, onBlur, error } = props;
//   const { field } = useController(props);

//   const fileInputRef = useRef<HTMLInputElement>(null);

//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     if (!files || files.length === 0) {
//       return;
//     }
//     const file = files[0];
//     if (file) {
//       if (!allowedFileTypes.includes(file.type)) {
//         onChange({
//           base64: "",
//           fileName: "",
//           error: "Unsupported file type. Please upload a valid file.",
//         });
//         return;
//       }
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         onChange({
//           base64: reader.result as string,
//           fileName: file.name,
//         });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleRemove = () => {
//     field.onChange(null);
//     if (fileInputRef.current) {
//       fileInputRef.current.value = "";
//     }
//   };

//   const handleFileClick = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };

//   return (
//     <div className="file-upload">
//       {label && <label className="text-input-label">{label}</label>}
//       <input
//         type="file"
//         onChange={handleFileChange}
//         onBlur={onBlur}
//         ref={fileInputRef}
//         style={{ display: "none" }}
//       />
//       <div className="file-upload-container">
//         <div className="file-upload-choose" onClick={handleFileClick}>
//           <span className="file-upload-text">Choose file</span>
//           <AttachFile className="file-upload-icon" />
//         </div>
//         {field.value?.fileName && (
//           <div className="file-upload-file-name">
//             {field.value.fileName}
//             <Cancel
//               className="file-upload-remove-icon"
//               onClick={handleRemove}
//             />
//           </div>
//         )}
//       </div>
//       {error && <div className="form-error">{error}</div>}
//     </div>
//   );
// };

// export default FileUpload;
