import "./Button.scss";

export const Button = ({
  onClick,
  title,
  disable,
  type,
  className,
}: {
  title: string;
  onClick?: () => void;
  disable?: boolean;
  type?: string;
  className?: string;
}) => {
  return (
    <div
      onClick={onClick}
      className={`button-container ${className} ${disable ? "disable" : ""}`}
      role="button"
    >
      <span className="title">{title}</span>
    </div>
  );
};
