import { forwardRef } from "react";
import "./HCDatePicker.scss";
import { UseControllerProps, useController } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";

type HCDatePickerProps = {
  label?: string;
  disableFuture: boolean;
};

export const HCDatePicker = forwardRef(
  (props: UseControllerProps & HCDatePickerProps, ref) => {
    const { label, disableFuture } = props;
    const { field, fieldState } = useController(props);

    return (
      <div className="hc-date-picker">
        {label && <label className="hc-date-picker-label">{label}</label>}
        <div className="hc-date-picker-wrapper">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker {...field} disableFuture={disableFuture} />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        {fieldState.error && (
          <div className="form-error">{fieldState.error.message}</div>
        )}
      </div>
    );
  }
);
