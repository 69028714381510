import { BottomBar, NavBar, SickIntro } from "../components";

import { FloatingButtons } from "../components/common";

const Sick = () => {
  return (
    <div>
      <NavBar />
      <SickIntro />
      <FloatingButtons />
      <BottomBar />
    </div>
  );
};

export default Sick;
