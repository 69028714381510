import "./TrustUs.scss";

import LockIcon from "@mui/icons-material/Lock";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PublicIcon from "@mui/icons-material/Public";

export const TrustUs = () => {
  return (
    <div className="trust-container">
      <div className="trust-container-section1">
        <div className="trust-container-section1-text">Why Trust Us?</div>
      </div>
      <div className="trust-container-section2">
        <div className="trust-container-section2-wrapper">
          <div className="trust-container-section2-wrapper-section">
            <div className="trust-container-section2-wrapper-section-img-wrapper">
              <AdminPanelSettingsIcon sx={{ fontSize: 60, color: "white" }} />
            </div>
            <div>
              <div className="trust-container-section2-wrapper-section-text">
                Authentic
              </div>
              <div className="trust-container-section2-wrapper-section-data">
                From Registered Indian Medical Practitioners only
              </div>
            </div>
          </div>
          <div className="trust-container-section2-wrapper-section">
            <div className="trust-container-section2-wrapper-section-img-wrapper">
              <LockIcon sx={{ fontSize: 60, color: "white" }} />
            </div>
            <div>
              <div className="trust-container-section2-wrapper-section-text">
                Secure
              </div>
              <div className="trust-container-section2-wrapper-section-data">
                Your data is secure and encrypted
              </div>
            </div>
          </div>
          <div className="trust-container-section2-wrapper-section">
            <div className="trust-container-section2-wrapper-section-img-wrapper">
              <PublicIcon sx={{ fontSize: 60, color: "white" }} />
            </div>
            <div>
              <div className="trust-container-section2-wrapper-section-text">
                Convenient
              </div>
              <div className="trust-container-section2-wrapper-section-data">
                Manage your health when it suits you, from anywhere
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
