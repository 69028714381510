import "./TermsAndConditionsIntro.scss";

export const TermsAndConditionsIntro = () => {
  const TERMS_AND_CONDITIONS = [
    {
      title: "Terms of use",
      desc: 'This website (Site) is operated by Doctors Certificate.com (we, our or us).  It is available at: <a href="https://doctorscertificate.com/">https://doctorscertificate.com/</a>  and may be available through other addresses or channels.',
    },
    {
      title: "Services",
      desc: "Doctors Certificate.com is a platform (or a facilitating agency) that connects patients with doctor partners through a technology platform that assists doctor partners to manage their independent practices. Certificates to minors require the consent of the guardian and no certificate is issued without their approval.\nDoctorsCertificate.com provides no guarantee that a telehealth consultation is appropriate for your circumstances and does not provide any medical or health advice or services to you. MedicalCertificate.in shall not be held liable/responsible for non-issuance of Medical Certificate by the Registered Medical Practioner or any consequential situations arising out after issuance of Medical Certificate. You agree that the acceptance/denial of the Medical Certificate issued for all practical purposes is not within the realms of liability of MedicalCertificate.in. You agree that MedicalCertificate.in is merely a facilitating agency and has no direct involvement/role in the issuance of medical certificate and issuance of Medical Certificates.\nWe reserve the right to cancel or suspend your access to your subscription/service without notice and at our sole discretion.\nPriority orders are serviced within 30 mins* within working hours, where the customer shares all their details, and attends the Doctor consultation. Handwritten prescriptions and medical notes are issued by the Doctor. The seal and the sign may be digitally printed based on the discretion of the Doctor.",
    },
    {
      title: "Your Responsibilities",
      desc: "You agree to provide all information requested by us, Partner Doctors and Partner Providers and warrant that all information which you provide is true and fully complete.\nIf you do not understand any question asked in our questionnaire (on the Platform) or any advice given by a Partner Doctor and/or Partner Provider, you should seek clarification immediately.",
    },
    {
      title: "Consent",
      desc: "By accessing and/or using our Site, you agree to these terms of use and our Privacy Policy (available on our site). (Terms). Please read these Terms carefully and immediately cease using our Site if you do not agree to them.",
    },
    {
      title: "Variations",
      desc: "We may, at any time and at our discretion, vary these Terms by publishing the varied terms on our Site. We recommend you check our Site regularly to ensure you are aware of our current terms. Materials and information on this Site (Content) are subject to change without notice. We do not undertake to keep our Site up-to-date and we are not liable if any Content is inaccurate or out-of-date.",
    },
    {
      title: "License to use our Site",
      desc: "We grant you a non-exclusive, royalty-free, revocable, worldwide, non-transferable license to use our Site in accordance with these Terms.  All other uses are prohibited without our prior written consent.",
    },
    {
      title: "Prohibited Conduct",
      desc: "You must not do or attempt to do anything: that is unlawful; prohibited by any laws applicable to our Site; which we would consider inappropriate; or which might bring us or our Site into disrepute, including (without limitation):\n<li style='margin-top: 10px;margin-left: 12px;'><span>Anything that would constitute a breach of an individual’s privacy (including uploading private or personal information without an individual’s consent) or any other legal rights</span></li>\n<li style='margin-top: 10px;margin-left: 12px;'><span>Using our Site to defame, harass, threaten, menace or offend any person</span></li>\n<li style='margin-top: 10px;margin-left: 12px;'><span>Interfering with any user using our Site;</span></li>\n<li style='margin-top: 10px;margin-left: 12px;'><span>Tampering with or modifying our Site, knowingly transmitting viruses or other disabling features, or damaging or interfering with our Site, including (without limitation) using trojan horses, viruses or piracy or programming routines that may damage or interfere with our Site</span></li>",
    },
  ];

  const hasHTML = (desc: string) => /<\/?[a-z][\s\S]*>/i.test(desc);

  return (
    <div className="container">
      <div className="top-title">Terms and Conditions</div>
      {TERMS_AND_CONDITIONS.map((item, index) => (
        <div key={index}>
          <div className="terms-and-conditions-title">{item.title}</div>
          {hasHTML(item.desc) ? (
            <div
              className="terms-and-conditions-desc"
              dangerouslySetInnerHTML={{
                __html: `<ul style="padding-left: 0px;margin-top: 0px">${item.desc}</ul>`,
              }}
            />
          ) : (
            item.desc.split("\n").map((line, i) => (
              <div className="terms-and-conditions-desc" key={i}>
                {line}
              </div>
            ))
          )}
        </div>
      ))}
    </div>
  );
};
