import "./ThreeSteps.scss";

import { Button } from "./Button";
import Step1 from "../../assets/images/cert-step-1.png";
import Step3 from "../../assets/images/cert-step-3.png";
import Step2 from "../../assets/images/cert-step-2.png";

export const ThreeSteps = ({
  onClick,
  title,
  buttonTitle,
}: {
  title: string;
  onClick: () => void;
  buttonTitle: string;
}) => {
  const renderStepOneAndTwo = () => {
    return (
      <div>
        <div className="medical-certi-steps-container-section-2">
          <div className="medical-certi-steps-container-section-2-step-1">
            <div className="medical-certi-steps-container-section-2-step-1-wrapper">
              <div className="medical-certi-steps-container-section-2-step-1-wrapper-section-1">
                <img src={Step1} alt="cert-step-1" className="cert-step-1" />
              </div>
              <div className="medical-certi-steps-container-section-2-step-1-wrapper-section-2">
                <div className="medical-certi-steps-container-section-2-step-1-wrapper-section-2-title">
                  Step 1 : Submit your request
                </div>
                <div className="medical-certi-steps-container-section-2-step-1-wrapper-section-2-desc">
                  Complete our quick medical questionnaire and submit your
                  request for the medical certificate.
                </div>
              </div>
            </div>
          </div>
          <div className="medical-certi-steps-container-section-2-step-2">
            <div className="medical-certi-steps-container-section-2-step-2-wrapper">
              <div className="medical-certi-steps-container-section-2-step-2-wrapper-section-1">
                <img src={Step2} alt="cert-step-1" className="cert-step-1" />
              </div>
              <div className="medical-certi-steps-container-section-2-step-2-wrapper-section-2">
                <div className="medical-certi-steps-container-section-2-step-2-wrapper-section-2-title">
                  Step 2: Consultation by a certified doctor
                </div>
                <div className="medical-certi-steps-container-section-2-step-2-wrapper-section-2-desc">
                  Our doctor will review your request and reach out to you.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCertStepsTitle = () => {
    return (
      <div className="medical-certi-steps-container-section-1">
        <div className="medical-certi-steps-container-section-1-step-1">
          <div className="medical-certi-steps-container-section-1-step-1-section-1">
            <div className="medical-certi-steps-container-section-1-step-1-section-1-text">
              {title}
            </div>
          </div>
          <div className="medical-certi-steps-container-section-1-step-1-section-2">
            <div className="medical-certi-steps-container-section-1-step-1-section-2-divider"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div className="medical-certi-steps-container-section-3">
        <div className="medical-certi-steps-container-section-3-step-1">
          <div className="medical-certi-steps-container-section-3-step-1-section-1">
            <img src={Step3} height={150} alt="cert-step-3" className="cert-step-3" />
          </div>
          <div className="medical-certi-steps-container-section-3-step-1-section-2">
            <div className="medical-certi-steps-container-section-3-step-1-section-2-title">
              Step 3 : Receive your certificate
            </div>
            <div className="medical-certi-steps-container-section-3-step-1-section-2-desc">
              Get your medical certificate via WhatsApp within 30 minutes* and
              the physical copy via post within 8-10 business days, if you have
              opted for it.
            </div>
          </div>
          <div className="medical-certi-steps-container-section-3-step-1-section-3">
            <Button title={buttonTitle} onClick={onClick} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="medical-certi-steps-container">
      {renderCertStepsTitle()}
      {renderStepOneAndTwo()}
      {renderStepThree()}
    </div>
  );
};
