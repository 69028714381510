import "./BottomBar.scss";
import AppLogo from "../assets/images/app-logo.jpg";
import { useNavigate } from "react-router-dom";
import { X, Instagram, LinkedIn, YouTube } from "@mui/icons-material";

export const BottomBar = () => {
  const globalOfficeAddresses = [
    "Zviad Gamsakhurdia Embankment 34, 0105 Tbilisi, Georgia",
    "30 Churchill Pl, London E14 5RE, United Kingdom",
  ];
  const cityClinicLocations = [
    "Mediclinics Mumbai, Lotus Star, Andheri East Plot No. D-5 Road No. 20, Marol, MIDC, Andheri East, Mumbai, Maharashtra 400093 ",
    "Mediclinics Delhi NCR, Udyog Vihar 90b Delhi – Jaipur Expressway, Sector 18, Gurugram – 122001, Haryana",
  ];

  const bottomBarLinks = [
    { title: "About Us", link: "/about-us" },
    { title: "FAQ'S", link: "/frequently-asked-questions" },
    { title: "Privacy Policy", link: "/privacy-policy" },
    { title: "Terms of Services", link: "/terms-and-conditions" },
    {
      title: "Refund/Cancellation Policy",
      link: "/refund-cancellation-policy",
    },
  ];

  const supportedCities1 = [
    "bangalore",
    "delhi",
    "mumbai",
    "pune",
    "hyderabad",
    "chennai",
    "kolkata",
    "ahmedabad",
    "jaipur",
    "lucknow",
    "kanpur",
    "bhubaneswar",
    "indore",
    "chandigarh",
    "Visakhapatnam",
    "Bhopal",
    "Patna",
    "Nagpur",
    "Coimbatore",
    "Vijayawada",
    "Dhanbad",
    "Srinagar",
    "Thrissur",
    "Kochi",
    "Vadodara",
  ];
  const supportedCities2 = [
    "Gorakhpur",
    "Goa",
    "Aizawl",
    "Gangtok",
    "Amravati",
    "Bokaro",
    "Ghaziabad",
    "Raipur",
    "Ranchi",
    "Surat",
    "Shillong",
    "Tirupati",
    "Varanasi",
    "Kozhikode",
    "Prayagraj",
    "Thiruvananthapuram",
    "Meerut",
    "Nashik",
    "Mangalore",
    "Ludhiana",
    "Kolhapur",
    "Guwahati",
    "Gwalior",
    "Nellore",
  ];

  const navigate = useNavigate();

  const retureTitle = (title: string) => {
    return <div className="global-office-add-title">{title}</div>;
  };

  const returnAddress = (address: string[]) => {
    return address.map((add: string, index: number) => (
      <div key={index} className="global-office-address">
        {add}
      </div>
    ));
  };

  const renderBottomBarLinks = () => {
    return (
      <div>
        {bottomBarLinks.map(
          ({ title, link }: { title: string; link: string }, index: number) => (
            <div
              key={index}
              className="bottom-bar-link-label"
              onClick={() => navigate(link)}
            >
              {title}
            </div>
          )
        )}
      </div>
    );
  };

  const renderSupportedCities = (cities: string[]) => {
    return cities.map((city, index) => (
      <div key={index} className="supported-cities">
        doctors certificate in {city}
      </div>
    ));
  };

  const renderSocialMediaFollow = (color: string, Icon: any, url: string) => {
    return (
      <div
        className="social-media-icon"
        style={{ backgroundColor: color, cursor: "pointer" }}
        onClick={() => window.open(url, "_blank")}
      >
        <Icon sx={{ color: "#fff" }} />
      </div>
    );
  };

  return (
    <div className="bottom-bar-top-container">
      <div className="bottom-bar-container">
        <div className="column1">
          <img src={AppLogo} alt="AppLogo" className="app-logo" />
          <div className="number1-doc-cert">
            🌎 No. #1 online medical certificate issuance & telemedicine
            platform abiding by the NMC & WHO guidelines.
          </div>
          {renderBottomBarLinks()}
          <div className="social-media-icons-container">
            {renderSocialMediaFollow(
              "#a82400",
              YouTube,
              "https://www.youtube.com"
            )}
            {renderSocialMediaFollow(
              "#ea2c59",
              Instagram,
              "https://www.instagram.com"
            )}
            {renderSocialMediaFollow("#000", X, "https://www.twitter.com")}
            {renderSocialMediaFollow(
              "#007bb6",
              LinkedIn,
              "https://www.linkedin.com"
            )}
          </div>
        </div>
        <div className="column2">{renderSupportedCities(supportedCities1)}</div>
        <div className="column3">{renderSupportedCities(supportedCities2)}</div>
        <div className="column4">
          <div>
            {retureTitle("Global Office Addresses")}
            {returnAddress(globalOfficeAddresses)}
          </div>
          <div>
            {retureTitle("City Clinic Locations (Powered by MediClinics)")}
            {returnAddress(cityClinicLocations)}
          </div>
        </div>
      </div>
    </div>
  );
};
