import { useState } from "react";
import "./FaqIntro.scss";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const FaqIntro = () => {
  const faqData = [
    {
      question: "All about Medical Certificates Online",
      answer:
        "Understand everything about online medical certificates and make the right decision about your health.",
    },
    {
      question: "When will I receive my medical certificate?",
      answer:
        "You will receive your digital medical certificates within 30 mins* of submitting the medical form (priority) and hard copy within 3-4 working days*.",
    },
    {
      question: "Will my employer/ education institute accept my certificate?",
      answer:
        "Yes, all our medical notes are as per NMC & WHO guidelines. If your employer/ education institute has any clarifications they could reach out to the coordinates of the Doctor on the medical certificate issued. ",
    },
    {
      question: "Which Doctor can issue a medical certificate?",
      answer:
        "A Registered Medical Practioner or Doctor who has been recognized by any state/country  is eligible to issue a medical certificate to any person based on their discretion.",
    },
    {
      question:
        "In which case do I need to visit a doctor physically or get online consultation?",
      answer:
        "If your condition is not improving despite taking all the measures suggested, then you need to visit a doctor physically.",
    },
  ];

  const [selectedQuestion, setSelectedQuestion] = useState(-1);

  const navigate = useNavigate();

  const toggleAnswer = (index: number) => {
    setSelectedQuestion((prevSelected) =>
      prevSelected === index ? -1 : index
    );
  };

  const renderFaqQnAndAns = () => {
    return (
      <div>
        <div className="faq-title">Frequently Asked Questions</div>
        {faqData.map((faqItem, index) => (
          <div
            key={index}
            className={`faq-section ${
              selectedQuestion === index ? "open" : ""
            }`}
            onClick={() => toggleAnswer(index)}
          >
            <div className="faq-qn-icon">
              <div
                className={`${
                  selectedQuestion === index
                    ? "faq-question-text-open"
                    : "faq-question-text"
                }`}
              >
                {faqItem.question}
              </div>
              {selectedQuestion !== index && (
                <div className="add-icon-wrapper">
                  <Add sx={{ color: "#fff", fontSize: 15 }} />
                </div>
              )}
            </div>
            {selectedQuestion === index && (
              <div className="faq-answer-text">{faqItem.answer}</div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderCard = () => {
    return (
      <div className="card-container">
        <div className="card-container-section1">
          <div className="card-container-section1-title1">
            Tele-consultation and Online Medical Certificate Services
          </div>
          <div className="card-container-section1-title2">
            Enabling affordable healthcare with instant doctor consultations and
            medical certificate services across the globe
          </div>
          <div
            onClick={() => navigate("/sick-certificate/form")}
            className="card-container-section1-button-wrapper"
            role="button"
          >
            <span className="card-container-section1-button-text ">
              Book an appointment
            </span>
          </div>
        </div>
        <div className="card-container-section2">
          <img
            src="https://medicalcertificate.in/wp-content/uploads/2024/03/0-a525d2b3-970b-4184-81fb-3faec03b77c8-512x512.jpg"
            alt="faq-section1image"
            className="card-container-section2-image"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="faq-container">
      <div className="faq-container-section1">{renderCard()}</div>
      <div className="faq-container-section2">{renderFaqQnAndAns()}</div>
    </div>
  );
};
