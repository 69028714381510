import "./CertificateCard.scss";

import { Button } from "../common";

export const CertificateCard = ({
  imageUrl,
  alt,
  title,
  desc,
  buttonTitle,
  disable,
  onClick,
}: {
  imageUrl: string;
  alt: string;
  title: string;
  desc: string;
  buttonTitle: string;
  disable?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div className="cert-card-wrapper">
      <img src={imageUrl} alt={alt} height={150} />
      <div className="text-wrapper">
        <span className="text-wrapper-title">{title}</span>
        <span className="text-wrapper-desc">{desc}</span>
      </div>
      <Button
        title={buttonTitle}
        onClick={onClick}
        disable={disable}
        className="card-button"
      />
    </div>
  );
};
