import "../../components/homeComponents/homeIntro.scss";

import { Button, Testimonials, ThreeSteps, TrustUs } from "../common";

import { CertificateCard } from "./index";
import DoctorHomeImage from "../../assets/images/doctor-home.jpg";
import FitnessImage from "../../assets/images/med-fitness-cert.jpg";
import FormF1Image from "../../assets/images/form-f1-cert.jpg";
import SickImage from "../../assets/images/sick-leave-cert.jpg";
import { useNavigate } from "react-router-dom";

export const HomeIntro = () => {
  const navigate = useNavigate();

  const types = [
    "✔️ Sick Certificate",
    "✔️ Accepted by airlines, banks, public offices, colleges & IT companies",
    "✔️ Handwritten certificate & shipping options available",
    "✔️ From the comfort of your home",
    "✔️ Get a soft copy within 30 minutes*",
    "✔️ Abiding by NMC & WHO Guidelines",
  ];

  const renderTypes = () => {
    return (
      <div className="types-wrapper">
        {types.map((type, index) => {
          return <div key={index}>{type}</div>;
        })}
      </div>
    );
  };

  const certificatesWorksYou = () => {
    return (
      <div>
        <div className="certificates-container-section1">
          <div className="certificates-container-section1-wrapper">
            <div className="certificates-container-section1-wrapper-text">
              Online Medical Certificates that work for you
            </div>
          </div>
        </div>
        <div className="certificates-container-section2">
          <div className="certificates-container-section2-wrapper">
            <div className="certificates-container-section2-wrapper-section">
              <CertificateCard
                imageUrl={SickImage}
                alt="SickImage"
                buttonTitle="get sick leave certificate"
                title="Sick Leave Medical Certificate"
                desc="When you’re not feeling well, the prospect of booking and attending a doctor’s appointment at a hospital or clinic can be daunting. Instead, obtain a sick leave certificate online from the comfort of your own home in just a few minutes."
                onClick={() => navigate("form")}
              />
              <CertificateCard
                imageUrl={FitnessImage}
                alt="FitnessImage"
                buttonTitle="Get fitness Certificate"
                title="Medical Fitness Certificate"
                desc="Require a fitness certificate to confirm your good health for your office, college or sports? Apply for an online fitness certificate, share some basic information, and receive your medical fitness certificate quickly and conveniently."
                onClick={() => navigate("form")}
              />
              <CertificateCard
                imageUrl={FormF1Image}
                alt="FormF1Image"
                buttonTitle="Coming soon"
                title="Form 1A Fitness Certificate"
                desc="Searching for a Form 1A fitness certificate to confirm your good health when renewing your driver’s license or applying for an International Driving License?"
                disable
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="home-container">
        <div className="home-container-section-1">
          <div className="home-container-section-1-title-wrapper">
            <div className="home-container-section-1-title-wrapper-text-1">
              Get a Medical
            </div>
            <div className="home-container-section-1-title-wrapper-text-2">
              Certificate Online in minutes
            </div>
          </div>
          <div className="home-container-section-1-types">{renderTypes()}</div>
          <div className="home-container-section-1-apply">
            <Button title="Apply now" onClick={() => navigate("form")} />
          </div>
        </div>
        <div className="home-container-section-2">
          <img
            src={DoctorHomeImage}
            alt="doctor-img"
            className="doctor-image-wrapper"
          />
        </div>
      </div>
      <TrustUs />
      <div className="certificates-container">{certificatesWorksYou()}</div>
      <Testimonials />
      <ThreeSteps
        title="Get Your Medical Certificate In 3 Easy Steps!"
        onClick={() => navigate("form")}
        buttonTitle="Apply now"
      />
    </div>
  );
};
