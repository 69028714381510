import { BottomBar, NavBar } from "../components";

import { FloatingButtons } from "../components/common";
import { FitnessIntro } from "../components/fitnessComponents";

const Fitness = () => {
  return (
    <div>
      <NavBar />
      <FitnessIntro />
      <FloatingButtons />
      <BottomBar />
    </div>
  );
};

export default Fitness;
