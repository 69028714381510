import { NavBar } from "../components";
import { FloatingButtons } from "../components/common";
import { RefundIntro } from "../components/refundComponents";

const Refund = () => {
  return (
    <div>
      <NavBar />
      <RefundIntro />
      <FloatingButtons />
    </div>
  );
};

export default Refund;
