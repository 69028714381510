import "./PrivacyPolicyIntro.scss";

export const PrivacyPolicyIntro = () => {
  const PRIVACY_POLICIES = [
    {
      title: "",
      desc: 'This Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us, offline or online, including through our <a href="https://doctorscertificate.com/">https://doctorscertificate.com/</a>. In this Privacy Policy “us”, “we”, or “DC.COM” means DoctorsCertificate.com.\nThis privacy policy (“Privacy Policy”) explains how we collect, use, share, disclose and protect Personal Information about the Users of the Services, including the Practitioners, the End-Users, and the visitors of Website (jointly and severally referred to as “you” or “Users” in this Privacy Policy). We created this Privacy Policy to demonstrate our commitment to the protection of your privacy and your personal information. Your use of and access to the Services is subject to this Privacy Policy and our Terms of Use.\nBy using the services or by otherwise giving us your information, you will be deemed to have read, understood, and agreed to the practices and policies outlined in this privacy policy and agree to be bound by the privacy policy. You hereby consent to our collection, use, and sharing, disclosure of your information as described in this privacy policy. We reserve the right to change, modify, add or delete portions of the terms of this privacy policy, at our sole discretion, at any time. If you do not agree with this privacy policy at any time, do not use any of the services or give us any of your information. If you use the services on behalf of someone else (such as your child) or an entity (such as your employer/ friend/ relative), you represent that you are authorized by such individual or entity to\n(i) Accept this privacy policy on such an individual’s or entity’s behalf, and\n(ii) Consent on behalf of such individual or entity to our collection, use, and disclosure of such individual’s or entity’s information as described in this privacy policy.',
    },
    {
      title: "Personal information",
      desc: "The types of personal information we may collect about you include:\n<li style='margin-left: 12px;'>Your  name;</li><li style='margin-left: 12px;'>Images of you;</li><li style='margin-left: 12px;'>Your contact details, including email address, mailing address, street address and/or telephone number/ mobile number; </li><li style='margin-left: 12px;'>Your marital status and/or anniversary;</li><li style='margin-left: 12px;'>Your age and/or date of birth/ gender;</li><li style='margin-left: 12px;'>Your payment mode details;</li><li style='margin-left: 12px;'>Your demographic information, such as postcode;</li><li style='margin-left: 12px;'>Your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition\nsources,search queries and/or browsing behaviour;</li>\nWe may collect these types of personal information directly from you or from third parties.",
    },
    {
      title: "Collection and use of personal information",
      desc: "We may collect, hold, use and disclose personal information for the following purposes: <li style='margin-left: 12px;'>To enable you to access and use our Site, associated applications and associated social media platforms;</li>",
    },
    {
      title:
        "How we treat personal information that is also sensitive information",
      desc: "Sensitive information is a subset of personal information that is given a higher level of protection under the Indian Privacy Principles. <strong>Sensitive information</strong>  means information relating to your racial or ethnic origin, political opinions, religion, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation or practices, criminal records, health information or biometric information. ",
    },
    {
      title: "Privacy Policy and Data Protection",
      desc: "All personal information that we process is kept confidential on our secure database. We will use your personal information to provide you with our services, communicating with you regarding your subscription and responding to complaints, for administration and accounting, for marketing and to ensure that the content and services that we offer are tailored to your needs and interests.\nIf you have provided us with information about another person, you confirm that they consent to the processing of their personal information, including their sensitive personal information, and that you have informed them of our identity and the purposes (as set out above) for which their personal information will be processed\nIn the event that you (whether on your own behalf or on behalf of someone else) choose to pay for our services you will be required to provide your name, email, mobile no., address and payment account details, which will be passed immediately to our payment provider via a secure link. We will keep your name and address confidential on our secure database and process these in accordance with this Data Protection and Privacy Notice.\nFor quality control and training purposes, we may monitor or record your communications with us.",
    },
  ];

  function renderDescWithLineBreaksAndHTML(desc: string) {
    return desc.split("\n").map((line, index) => {
      if (line.includes("<") && line.includes(">")) {
        return (
          <span
            key={index}
            dangerouslySetInnerHTML={{
              __html: `<ul style="padding-left: 0px;margin-top: 0px">${line}</ul>`,
            }}
          />
        );
      } else {
        return (
          <div style={{ marginTop: 10 }} key={index}>
            {line}
          </div>
        );
      }
    });
  }

  return (
    <div className="privacy-container">
      <div className="privacy-top-title">Privacy Policy</div>
      {PRIVACY_POLICIES.map((item, index) => (
        <div key={index}>
          <div className="privacy-title">{item.title}</div>
          <div className="privacy-desc">
            {renderDescWithLineBreaksAndHTML(item.desc)}
          </div>
        </div>
      ))}
    </div>
  );
};
