import { NavBar } from "../components";
import { FloatingButtons } from "../components/common";
import { PrivacyPolicyIntro } from "../components/privacyPolicyComponents";

const PrivacyPolicy = () => {
  return (
    <div>
      <NavBar />
      <PrivacyPolicyIntro />
      <FloatingButtons />
    </div>
  );
};

export default PrivacyPolicy;
