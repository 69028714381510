import "./AboutIntro.scss";
import { GlobalFootprint, ChatWithExpert, AboutUs } from "./index";

export const AboutIntro = () => {
  const renderConvenientExpert = () => {
    return (
      <div className="convenient-expert-grid-area">
        <div className="convenient-expert-title">
          Convenient, Expert, Healthcare at Affordable Prices
        </div>
        <div className="convenient-expert-desc">
          Doctor Certificate is a digital platform that connects certified
          physicians with patients seeking health-related services. We follow
          the guidelines outlined in the WHO telemedicine guidelines and the
          issuance of medical certificates. Our goal is to provide you with
          confidential online consultations with registered doctors and
          hassle-free medical certificates within 30 mins*, from any place in
          the world. Our medical certificates are issued by registered medical
          practitioners and are verifiable, and accepted by all educational
          institutions, airlines, financial institutions, government offices,
          and IT firms throughout the World. Trusted by over 45,000+ satisfied
          clients.
        </div>
      </div>
    );
  };

  return (
    <div className="about-intro-container">
      <div className="about-intro-section1">
        <div className="about-us-grid-area">
          <AboutUs />
        </div>
        {renderConvenientExpert()}
      </div>
      <div className="about-intro-section3">
        <ChatWithExpert />
      </div>
      <div className="about-intro-section4">
        <GlobalFootprint />
      </div>
    </div>
  );
};
