import { NavBar } from "../components";
import { FaqIntro } from "../components/faqComponents";

import { FloatingButtons } from "../components/common";

const Faq = () => {
  return (
    <div>
      <NavBar />
      <FaqIntro />
      <FloatingButtons />
    </div>
  );
};

export default Faq;
