import "./SickIntro.scss";

import {
  AddHomeWork,
  FitnessCenter,
  FlightTakeoff,
  School,
  Sick,
  Snowshoeing,
} from "@mui/icons-material";
import { Button, Testimonials, ThreeSteps, TrustUs } from "../common";

import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

type SickLeaveType = {
  img1: ReactElement;
  title1: string;
  img2: ReactElement;
  title2: string;
  img3: ReactElement;
  title3: string;
};

const SickIntro = () => {
  const navigate = useNavigate();
  const types = [
    "✔️ Accepted by airlines, banks, public offices, colleges & IT companies",
    "✔️ Attested by Registered Indian MBBS, MD, MS Doctors only",
    "✔️ From the comfort of your home",
    "✔️ Get a soft copy within 30 minutes*",
    "✔️ Abiding by NMC Guidelines",
  ];

  const renderTypes = () => {
    return (
      <div className="types-wrapper">
        {types.map((type, index) => {
          return <div key={index}>{type}</div>;
        })}
      </div>
    );
  };

  const renderTypesOfSickLeaves = ({
    img1,
    title1,
    img2,
    title2,
    img3,
    title3,
  }: SickLeaveType) => {
    return (
      <div className="type-of-sick-leave-cert-section2-wrapper">
        <div className="type-of-sick-leave-cert-section2-wrapper-section">
          <div className="type-of-sick-leave-cert-section2-wrapper-section-img-wrapper">
            {img1}
          </div>
          <div className="type-of-sick-leave-cert-section2-wrapper-section-text">
            {title1}
          </div>
        </div>
        <div className="type-of-sick-leave-cert-section2-wrapper-section">
          <div className="type-of-sick-leave-cert-section2-wrapper-section-img-wrapper">
            {img2}
          </div>
          <div className="type-of-sick-leave-cert-section2-wrapper-section-text">
            {title2}
          </div>
        </div>
        <div className="type-of-sick-leave-cert-section2-wrapper-section">
          <div className="type-of-sick-leave-cert-section2-wrapper-section-img-wrapper">
            {img3}
          </div>
          <div className="type-of-sick-leave-cert-section2-wrapper-section-text">
            {title3}
          </div>
        </div>
      </div>
    );
  };

  const getAndWhyCertificate = ({
    title,
    desc,
    showButton = false,
  }: {
    title: string;
    desc: string;
    showButton?: boolean;
  }) => {
    return (
      <div className="get-and-why-cert">
        <div className="get-medical">
          <div className="get-medical-title">{title}</div>
          <div className="get-medical-desc">{desc}</div>
          {showButton && (
            <div className="get-medical-button-wrapper">
              <Button
                title="Get Sick Leave Certificate"
                onClick={() => navigate("form")}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="get-sick-leave-cert">
        <div className="get-sick-leave-cert-section-1">
          <div className="get-sick-leave-cert-section-1-title-wrapper">
            <div className="get-sick-leave-cert-section-1-title-wrapper-text">
              Get a Sick Leave
            </div>
            <div className="get-sick-leave-cert-section-1-title-wrapper-text">
              Certificate Online
            </div>
          </div>
          <div className="get-sick-leave-cert-section-1-types">
            {renderTypes()}
          </div>
          <div className="get-sick-leave-cert-section-1-apply">
            <Button
              title="Get Sick Leave Certificate"
              onClick={() => navigate("form")}
            />
          </div>
        </div>
      </div>
      <TrustUs />
      <div className="type-of-sick-leave-cert">
        <div className="type-of-sick-leave-cert-section1">
          <div className="type-of-sick-leave-cert-section1-text">
            Types of Sick Leave Certificates we Offer
          </div>
        </div>
        <div className="type-of-sick-leave-cert-section2">
          {renderTypesOfSickLeaves({
            img1: <AddHomeWork sx={{ fontSize: 60, color: "#0b582b" }} />,
            title1: "Work Sickness Certificate",
            img2: <School sx={{ fontSize: 60, color: "#0b582b" }} />,
            title2: "University/College/School Sickness Letter",
            img3: <Snowshoeing sx={{ fontSize: 60, color: "#0b582b" }} />,
            title3: "Event & Activity Cancellation Sickness Letter",
          })}

          {renderTypesOfSickLeaves({
            img1: <FitnessCenter sx={{ fontSize: 60, color: "#0b582b" }} />,
            title1: "Gym & Health Club Membership Cancellation",
            img2: <FlightTakeoff sx={{ fontSize: 60, color: "#0b582b" }} />,
            title2: "Travel & Holiday Cancellation Sickness Letter",
            img3: <Sick sx={{ fontSize: 60, color: "#0b582b" }} />,
            title3: "Chicken Pox Sickness Letter",
          })}
        </div>
        <div className="type-of-sick-leave-cert-section3">
          <Button
            title="Get Sick Leave Certificate"
            onClick={() => navigate("form")}
          />
        </div>
      </div>
      {/* <-- Line --> */}
      <div className="horizontal-line-wrapper">
        <div className="horizontal-line-wrapper-line"></div>
      </div>
      {/* <-- Get and Why Medical cert --> */}
      {getAndWhyCertificate({
        title: "Get a Medical Certificate For Leave On Priority",
        desc: "Are you in need of a medical certificate for sick leave? Now get asick leave certificate online in minutes from a Registered Medical Doctor from the comfort of your home. Share a few details, consult a Doctor online, and get your medical certificate for leave now.",
        showButton: true,
      })}
      {getAndWhyCertificate({
        title: "When Do You Need A Sick Leave Certificate?",
        desc: "If you feel unwell, it’s advisable to stay at home and prioritise your health. Although work or education may be significant, recuperating at home can expedite your recovery. If you are suffering from a contagious illness, it’s crucial to safeguard yourself and others by resting at home. It’s imperative to pay attention to your body and acknowledge the importance of rest. Requesting time off due to sickness can be difficult, and obtaining a medical certificate from a doctor can be a tedious process, particularly if it involves traveling, waiting in line, and explaining the situation to the doctor. However, instead of going through all the trouble, it’s best to consult a doctor online and receive your sick leave medical certificate in minutes.",
      })}
      {/* <-- Testimonials --> */}
      <Testimonials />
      {/* <-- Three steps --> */}
      <ThreeSteps
        title="Get Your Medical Certificate In 3 Easy Steps!"
        onClick={() => navigate("form")}
        buttonTitle="Get sick leave certificate"
      />
    </div>
  );
};

export default SickIntro;
