import "./ChatWithExpert.scss";
import { Check } from "@mui/icons-material";

export const ChatWithExpert = () => {
  const STEPS = [
    "Experienced Doctors delivering the best of healthcare services",
    "Short wait times with guaranteed delivery*",
    "Rated 5 Star on Trustpilot from over 2200+ Reviews",
    "Delivering quality service with empathy and humility",
  ];

  const openWhatsApp = () => {
    const whatsappNumber = "+919981373343";
    const url = `https://wa.me/${whatsappNumber}`;
    window.open(url, "_blank");
  };

  const listItems = STEPS.map((note, index) => (
    <div className="steps-wrapper" key={index}>
      <div className="circle-icon">
        <Check sx={{ color: "#fff", fontSize: 32 }} />
      </div>
      <div className="notes-points">{note}</div>
    </div>
  ));

  return (
    <div className="chat-with-expert-wrapper">
      <div className="section1">
        <div className="section1-title">Chat With An Expert.</div>
        <div className="section1-desc">
          Our health counsellors are equipped with all the information you need
          before making a decision. Be it custom formats or how does the process
          work, they help you with all queries.
        </div>
        <div
          onClick={openWhatsApp}
          className="section-button-wrapper"
          role="button"
        >
          <span className="section-button">Chat with an expert</span>
        </div>
      </div>
      <div className="section2">
        <div className="section2-title">Why Choose us</div>
        {listItems}
      </div>
    </div>
  );
};
