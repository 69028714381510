import "./GlobalFootprint.scss";

export const GlobalFootprint = () => {
  return (
    <div className="global-footprint-wrapper">
      <div className="global-footprint-title">Global Footprint</div>
      <div className="global-footprint-desc1">
        We are a telemedicine company with a global footprint. Our employees and
        partner Doctors work from various locations across the world with a
        constant focus of delivering the best possible healthcare at incredible
        speed.
      </div>
      <div className="global-footprint-desc2">
        Tbilisi | London | Delhi NCR | Mumbai | Bangalore | Hyderabad | Pune |
        Indore | Jaipur | Shadnagar | Cuttack
      </div>
      <div className="global-footprint-desc3">
        © 2024 | All Rights Reserved | Operated by Doctors Certificate Tech LLC
      </div>
    </div>
  );
};
