import { NavBar } from "../components";
import { AboutIntro } from "../components/aboutComponents";

import { FloatingButtons } from "../components/common";

const About = () => {
  return (
    <div>
      <NavBar />
      <AboutIntro />
      <FloatingButtons />
    </div>
  );
};

export default About;
