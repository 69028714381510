import "./SickForm.scss";

import {
  Button,
  HCDatePicker,
  HCSelect,
  RadioField,
  TextField,
  FileUpload,
} from "../common";
import { WarningAmber, CheckCircle, Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import { NavBar } from "../NavBar";
import Spinner from "../common/Spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import AppLogo from "../../assets/images/app-logo.jpg";

declare global {
  interface Window {
    Razorpay: any;
  }
}

const SickForm = () => {
  const COUNTRIRES = [{ value: "india", label: "India" }];

  const AGE = [{ value: "below18", label: "Below 18" }];

  const SPECIAL_FORMAT = [
    {
      id: "1",
      value: "yes",
      name: "Yes",
    },
    {
      id: "2",
      value: "no",
      name: "No",
    },
  ];

  const CHOOSE_DOC = [
    {
      id: "1",
      value: 399,
      name: "₹399 - Digital Certificate on Whatsapp (Non-Refundable)",
    },
    {
      id: "2",
      value: 599,
      name: "₹599 - Digital Certificate with prescription on Whatsapp - partial amount will be deducted",
    },
    {
      id: "3",
      value: 999,
      name: "₹999 - Handwritten Certificate on Whatsapp (Preferred by Colleges/Universities) (Non refundable)",
    },
    {
      id: "4",
      value: 1099,
      name: "₹1099 - Digital Certificate with prescription on Whatsapp (30 mins delivery)*",
    },
    {
      id: "5",
      value: 1299,
      name: "₹1299 - Handwritten Certificate on Whatsapp with shipping",
    },
    {
      id: "6",
      value: 1399,
      name: "₹1399 - Handwritten Certificate with prescription on Whatsapp (Bestseller)",
    },
    {
      id: "7",
      value: 1599,
      name: "₹1599 - Handwritten Certificate with prescription on Whatsapp with shipping",
    },
  ];

  const SEEKING = [
    {
      id: "1",
      value: "sickLeaveCert",
      name: "Sick leave certificate (For school/college/work)",
    },
    // { id: "2", value: "FitnessCert", name: "Fitness certificate" },
    // { id: "3", value: "recoveryCert", name: "Recovery certificate" },
    { id: "4", value: "caretakerCert", name: "Caretaker certificate" },
    {
      id: "5",
      value: "wfhCert",
      name: "Work from home certificate",
    },
  ];

  const NOTES = [
    "Our working hours are from <strong>09:00 am IST – 09:00 pm IST (Mon - Sat) | 10:00 am IST – 8:00 pm IST (Sun)</strong>. Any order placed before/ after working hours will be serviced in the next working slot. All cases are handled within 120 minutes during working hours. Priority service orders are processed within 30 minutes during working hours.",
    "We are an online platform thus cannot include the location on the certificate. For a medical document to be valid, it must be on the doctor's letterhead and contain accurate details of the doctor and patient. The doctor may digitally print or handwrite their seal and signature as they see fit in handwritten documents. The doctor's email is provided for verification. Confirm the document format and healthcare policy of your organization's requirements before using our service.",
    "After payment, the doctor will contact you for an online consultation and release the document accordingly. The diagnosis, treatment, and duration are determined by the doctor during the consultation.",
    "Certificate formats follow NMC Guidelines and cannot be changed. In case of a custom format, reach out to us at <a href='mailto:Doctorscertificate@gmail.com'>Doctorscertificate@gmail.com.</a>",
    "For all medical certificates, the online doctor consultation is only done with the patient and not the attendees.",
    "To check the validity of the Doctor, please check the National Medical Registrar. Do note we do not hold the responsibility for medical data being updated on government online portals. We work with certified partners and are not allowed to share our partner doctor's personal details or qualification details entailing misuse. However, if you have any concerns related to our certified medical professionals kindly check our terms and conditions page.",
  ];

  const navigate = useNavigate();

  const loadScript = (src: string) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    // Load the Razorpay script when the component mounts
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const [showSpinner, setShowSpinner] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { handleSubmit, control, formState, trigger } = useForm();

  const onRazorpaySubmit = async (formData: any) => {
    setShowSpinner(true);
    try {
      const { data } = await axios.post(
        "https://sheet.best/api/sheets/d82c738e-a5d5-48b2-b3f3-a5126e43d59d",
        { ...formData, paymentSuccess: "YES" }
      );
      console.log("api data", data);
      setApiSuccess(!!data);
    } catch (error) {
      setApiSuccess(false);
    }
    setShowSpinner(false);
    handleClickOpenDialog();
  };

  const apiCallToRegisterFailure = async (formData: any) => {
    try {
      await axios.post(
        "https://sheet.best/api/sheets/d82c738e-a5d5-48b2-b3f3-a5126e43d59d",
        { ...formData, paymentSuccess: "NO" }
      );
    } catch (error) {}
  };

  const onRazorpayModalDismiss = async (formData: any) => {
    setShowSpinner(true);
    await apiCallToRegisterFailure(formData);
    setShowSpinner(false);
  };

  const handlePayment = async (formatData: any) => {
    if (!window.Razorpay) {
      console.error("Razorpay SDK not loaded");
      return;
    }

    const options = {
      key: "rzp_live_ajONz8q78mUpZN",
      amount: formatData.amount * 100 ?? 0,
      currency: "INR",
      name: "Medical Health Certificate",
      description: "To get Certificate",
      image: AppLogo,
      handler: (response: any) => {
        onRazorpaySubmit({
          ...formatData,
          razorpayId: response.razorpay_payment_id,
        });
      },
      prefill: {
        name: formatData.firstName,
        email: formatData.emailOfPatient,
        contact: formatData.phone,
      },
      theme: {
        color: "#0b582b",
      },
      modal: {
        ondismiss: () => {
          onRazorpayModalDismiss(formatData);
        },
      },
    };
    const razorpay = new window.Razorpay(options);
    razorpay.on("payment.failed", async (response: any) => {
      await apiCallToRegisterFailure(formatData);
    });
    razorpay.open();
  };

  const onSubmit = async (data: any) => {
    console.log("errors", formState.errors);
    const selectedDate = new Date(data["selectDate"]);
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;
    const day = selectedDate.getDate();

    console.log("Before", data);
    const formatData = {
      ...data,
      amount: Number(data["chooseDoc"]),
      age: data["age"]?.value || "",
      country: data["country"]?.value || "India",
      selectDate: `${day}/${month}/${year}`,
      fileUpload: data["fileUpload"]?.base64 || "",
    };
    console.log("After", formatData);

    await handlePayment(formatData);
  };

  const renderChooseDoc = () => {
    return (
      <div className="choose-doc-wrapper">
        <label className="choose-doc-wrapper-label">
          Choose the Document Format you want
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="chooseDoc"
          control={control}
          rules={{
            required: "Enter a value for this field.",
          }}
          render={({ field }) => (
            <RadioField {...field} control={control} options={CHOOSE_DOC} />
          )}
        />
        <div className="choose-doc-wrapper-helper-text">
          Some workplaces require prescription in addition to your medical
          certificate. We recommend that you include a prescription if you are
          unsure about your workplace policies. For Physical shipping, documents
          ship within 3-4 working days.
        </div>
      </div>
    );
  };

  const renderOtherDetails = () => {
    return (
      <div className="other-details-wrapper">
        <label className="other-details-wrapper-label">
          Any other details you want to mention, please specify here
        </label>
        <div className="top-space"></div>
        <Controller
          name="otherDetails"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <textarea
              style={{ width: "100%", height: "80px", padding: "16px" }}
              {...field}
            ></textarea>
          )}
        />
      </div>
    );
  };

  const renderSpecialFormat = () => {
    return (
      <div className="special-format-wrapper">
        <label className="special-format-wrapper-label">
          Do you have a special format to be attested by the Doctor?
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="specialFormat"
          control={control}
          rules={{
            required: "Enter a value for this field.",
          }}
          render={({ field }) => (
            <RadioField {...field} control={control} options={SPECIAL_FORMAT} />
          )}
        />
        <div className="special-format-wrapper-helper-text">
          In case of special/custom format that is required to be attested by
          Doctor, our medical team will review the format and request additional
          reports/details if necessary. Cost for attesting special/custom format
          will be an extra cost of ₹250.
        </div>
      </div>
    );
  };

  const renderMedicalCertDate = () => {
    return (
      <div className="medical-cert-date-wrapper">
        <label className="medical-cert-date-wrapper-label">
          When do you want the medical certificate from?
          <em>*</em>
        </label>
        <div className="medical-cert-date-wrapper-helper-text">
          (Future date certificates cannot be issued.)
        </div>
        <div className="top-space"></div>
        <Controller
          name="selectDate"
          control={control}
          rules={{ required: "Date is required" }}
          render={({ field }) => {
            return (
              <HCDatePicker {...field} control={control} disableFuture={true} />
            );
          }}
        />
        <div className="medical-cert-date-wrapper-helper-text">dd-MMM-yyyy</div>
      </div>
    );
  };

  const renderDetailsOfMedicalProblem = () => {
    return (
      <div className="details-of-medical-problem-wrapper">
        <label className="details-of-medical-problem-wrapper-label">
          Details of medical problem that you recovered from
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="detailsOfMedicalProblem"
          control={control}
          rules={{ required: "Enter a value for this field." }}
          defaultValue=""
          render={({ field }) => <TextField {...field} control={control} />}
        />
        <div className="details-of-medical-problem-wrapper-helper-text">
          Outline your medical condition. Certain medical conditions cannot be
          outlined without reports like Dengue, Malaria, Typhoid, Fracture etc.
          The documents required to ascertain a medical ailment is based on the
          discretion of the doctor.
        </div>
      </div>
    );
  };

  const renderIamSeeking = () => {
    return (
      <div className="i-am-seeking-wrapper">
        <label className="i-am-seeking-wrapper-label">
          I am seeking
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="iAmSeeking"
          control={control}
          rules={{
            required: "Enter a value for this field.",
          }}
          render={({ field }) => (
            <RadioField {...field} control={control} options={SEEKING} />
          )}
        />
        <div className="i-am-seeking-wrapper-helper-text">
          *Fitness Certificate can be issued for new job joining, CARA, hair
          transplant, admission in school/ college etc.*For Recovery Certificate
          to be issued it is mandatory to have a Sick Note or Prescription that
          outlines your medical condition.
        </div>
      </div>
    );
  };

  const renderNameOfOrganization = () => {
    return (
      <div className="name-of-org-wrapper">
        <label className="name-of-org-wrapper-label">
          Full name and address of the Organization to whom the medical
          certificate will be submitted to
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="nameOfOrganization"
          control={control}
          rules={{ required: "Enter a value for this field." }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              control={control}
              placeholder="Organization Name, Street 1, House No XYZ, City, Pincode"
            />
          )}
        />
        <div className="name-of-org-wrapper-helper-text">
          Medical certificates to be addressed to a specific organization
          (School/College/Company) as per NMC guidelines. No medical certificate
          can be issued as 'Whomsoever It May Concern'. Not valid to submit in
          Courts/Will, valid for use in India.
        </div>
      </div>
    );
  };

  const renderAge = () => {
    for (let i = 19; i <= 60; i++) {
      AGE.push({ value: i.toString(), label: i.toString() });
    }

    return (
      <div className="age-wrapper">
        <label className="address-wrapper-label">
          Age of the patient
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="age"
          control={control}
          rules={{ required: "Age is required" }}
          render={({ field }) => (
            <HCSelect {...field} control={control} options={AGE} />
          )}
        />
        <div className="age-wrapper-helper-text">
          {
            " In case of a minor i.e. <18 years the Doctor consultation will be with the guardian/caretaker"
          }
        </div>
      </div>
    );
  };

  const renderAddress = () => {
    return (
      <div className="address-wrapper">
        <label className="address-wrapper-label">
          Address of the patient
          <em>*</em>
        </label>
        <div className="address-wrapper-helper-text">
          (As mentioned in the Aadhaar Card)
        </div>
        <div className="top-space"></div>
        <Controller
          name="streetAddress"
          control={control}
          rules={{ required: "Street address is required" }}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} control={control} label={"Street Address"} />
          )}
        />
        <div className="top-space"></div>
        <Controller
          name="addressLine2"
          control={control}
          rules={{ required: "Address Line 2 is required" }}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} control={control} label={"Address Line 2"} />
          )}
        />
        <div className="top-space"></div>
        <div className="address-wrapper-city-state">
          <Controller
            name="city"
            control={control}
            rules={{ required: "City is required" }}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} control={control} label={"City"} />
            )}
          />
          <Controller
            name="state"
            control={control}
            rules={{ required: "State is required" }}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} control={control} label={"State"} />
            )}
          />
        </div>
        <div className="top-space"></div>
        <div className="address-wrapper-postal-country">
          <Controller
            name="zipCode"
            control={control}
            rules={{
              required: "Postal / Zip Code is required",
              validate: {
                isZipcode: (val) =>
                  validator.isPostalCode(val, "IN") || "Invalid Zip-code",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                control={control}
                label={"Postal / Zip Code"}
              />
            )}
          />
          <Controller
            name="country"
            control={control}
            rules={{ required: "Country is required" }}
            render={({ field }) => (
              <HCSelect
                {...field}
                control={control}
                label={"Country"}
                options={COUNTRIRES}
              />
            )}
          />
        </div>
      </div>
    );
  };

  const renderCareOf = () => {
    return (
      <div className="care-of-wrapper">
        <label className="care-of-wrapper-label">
          Care of
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="careOf"
          control={control}
          rules={{
            required: "Care of is required",
            validate: {
              isCareOf: (val) =>
                validator.isAlpha(val, "en-US", { ignore: " " }) ||
                "Invalid Care of",
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              control={control}
              label={"Father/ Husband/ Guardian full name"}
            />
          )}
        />
      </div>
    );
  };

  const renderGender = () => {
    return (
      <div className="gender-wrapper">
        <label className="gender-wrapper-label">
          Gender of the patient
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="gender"
          control={control}
          rules={{
            required: "Gender is required",
          }}
          render={({ field }) => (
            <RadioField
              {...field}
              control={control}
              options={[
                { id: "1", value: "male", name: "Male" },
                { id: "2", value: "female", name: "Female" },
                { id: "3", value: "others", name: "Others" },
              ]}
            />
          )}
        />
      </div>
    );
  };

  const renderEmail = () => {
    return (
      <div className="email-wrapper">
        <label className="email-wrapper-label">
          Email of patient
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="emailOfPatient"
          control={control}
          defaultValue=""
          rules={{
            required: "Email is required",
            validate: {
              matchPattern: (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "Email address must be a valid address",
            },
          }}
          render={({ field }) => (
            <TextField {...field} control={control} defaultValue="" />
          )}
        />
      </div>
    );
  };

  const renderPhoneNumber = () => {
    return (
      <div className="phone-wrapper">
        <label className="phone-wrapper-label">
          Phone No. of Patient
          <em>*</em>
        </label>
        <div className="top-space"></div>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: "Phone No. is required",
            validate: {
              isPhoneNumber: (value) =>
                validator.isMobilePhone(value, "en-IN") ||
                "Invalid phone number",
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="tel"
              control={control}
              label={"Ideally the Whatsapp No."}
            />
          )}
        />
      </div>
    );
  };

  const renderPatientName = () => {
    return (
      <div className="name-wrapper">
        <label className="name-wrapper-label">
          Name of the Patient
          <em>*</em>
        </label>
        <div className="name-wrapper-helper-text">(As per Govt ID proof)</div>
        <div className="top-space"></div>
        <div className="name-wrapper-first-last">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: "Username is required",
              validate: {
                isFirstName: (val) =>
                  validator.isAlpha(val, "en-US", { ignore: " " }) ||
                  "Invalid first name",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} control={control} label={"First Name"} />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              validate: {
                isLastName: (val) =>
                  validator.isAlpha(val, "en-US", { ignore: " " }) ||
                  "Invalid last name",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} control={control} label={"Last Name"} />
            )}
          />
        </div>
      </div>
    );
  };

  const renderConfirmationDialog = () => {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="lg"
        className="confirmation-dialog"
        // onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            zIndex: 9999,
          }}
        >
          <Close />
        </IconButton>
        <div className={`dialog-container ${apiSuccess ? "success" : "fail"}`}>
          <section className="dialog-section1">
            <h1>{apiSuccess ? "Success!" : "Whoops!"}</h1>
            {apiSuccess ? (
              <CheckCircle sx={{ fontSize: 60 }} />
            ) : (
              <WarningAmber sx={{ fontSize: 60 }} />
            )}
          </section>
          <section className="dialog-section2">
            <div className="desc">
              {apiSuccess
                ? "Thank you for your payment! It has been successfully processed."
                : "Payment Unsuccessful. Please try again later or contact support for assistance."}
            </div>
            <Button
              className={`override-button ${apiSuccess ? "success" : "fail"}`}
              title="Ok"
              onClick={() => {
                handleClose();
                navigate("/");
              }}
            />
            <div
              className={`custom-shape-divider-top ${
                apiSuccess ? "success" : "fail"
              }`}
            >
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                  className="shape-fill"
                ></path>
              </svg>
            </div>
          </section>
        </div>
      </Dialog>
    );
  };

  const renderFileUpload = () => {
    return (
      <div className="file-upload-wrapper">
        <label className="file-upload-wrapper-label">
          Upload your Govt ID proof
          {/* <em>*</em> */}
        </label>
        <div className="top-space"></div>
        <Controller
          name="fileUpload"
          control={control}
          rules={{
            validate: {
              fileUploaded: (value) => {
                console.log("--", value);
                if (value && value.error) {
                  return value.error;
                }
                return true;
              },
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <FileUpload
                {...field}
                label="Id proof of the patient"
                control={control}
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                  trigger("fileUpload");
                }}
                onBlur={field.onBlur}
                error={error?.message}
              />
            );
          }}
        />
      </div>
    );
  };

  const renderSickForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderPatientName()}
        {renderPhoneNumber()} {/* TODO: Prefix to +91 */}
        {renderEmail()}
        {renderGender()}
        {renderCareOf()}
        {renderAddress()}
        {renderAge()}
        {renderNameOfOrganization()}
        {renderIamSeeking()}
        {renderDetailsOfMedicalProblem()}
        {renderMedicalCertDate()}
        {renderOtherDetails()}
        {renderSpecialFormat()}
        {renderFileUpload()}
        {renderChooseDoc()}
        <div className="button-wrapper">
          <Button
            type="submit"
            title="Submit"
            onClick={handleSubmit(onSubmit)}
            // onClick={handleClickOpenDialog}
          />
        </div>
      </form>
    );
  };

  const renderNote = () => {
    const listItems = NOTES.map((note, index) => (
      <li style={{ marginBottom: 10 }} key={index}>
        <span
          className="notes-text"
          dangerouslySetInnerHTML={{ __html: note }}
        ></span>
      </li>
    ));
    return (
      <div className="note-wrapper">
        <div className="note-title">Note</div>
        <ul>{listItems}</ul>
      </div>
    );
  };

  return (
    <div>
      {showSpinner && <Spinner />}
      <NavBar />
      <div className="sick-form-wrapper">
        {renderNote()}
        {renderSickForm()}
      </div>
      {renderConfirmationDialog()}
    </div>
  );
};

export default SickForm;
