import "./AboutUs.scss";
import { useNavigate } from "react-router-dom";

export const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="about-us-wrapper">
      <div className="about-us-title">About Us</div>
      <div className="about-us-desc">
        Doctors Certificate is transforming the future of online healthcare
      </div>
      <div
        onClick={() => navigate("form")}
        className="about-us-button-wrapper"
        role="button"
      >
        <span className="about-us-button">Consult a doctor</span>
      </div>
    </div>
  );
};
