import {
  About,
  Home,
  Sick,
  Faq,
  TermsAndConditions,
  PrivacyPolicy,
  Fitness,
} from "../pages";
import {
  Navigate,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";

import { SickForm } from "../components/sickComponents";
import Refund from "../pages/Refund";

const Routers = createBrowserRouter(
  [
    {
      path: "/",
      element: <Navigate to="/home" />,
    },
    {
      path: "/home",
      element: (
        <>
          <ScrollRestoration />
          <Home />
        </>
      ),
    },
    {
      path: "/home/form",
      element: (
        <>
          <ScrollRestoration />
          <SickForm />
        </>
      ),
    },
    {
      path: "/about-us",
      element: (
        <>
          <ScrollRestoration />
          <About />
        </>
      ),
    },
    {
      path: "/about-us/form",
      element: (
        <>
          <ScrollRestoration />
          <SickForm />
        </>
      ),
    },
    {
      path: "/sick-certificate",
      element: (
        <>
          <ScrollRestoration />
          <Sick />
        </>
      ),
    },
    {
      path: "/sick-certificate/form",
      element: (
        <>
          <ScrollRestoration />
          <SickForm />
        </>
      ),
    },
    {
      path: "/frequently-asked-questions",
      element: (
        <>
          <ScrollRestoration />
          <Faq />
        </>
      ),
    },
    {
      path: "/terms-and-conditions",
      element: (
        <>
          <ScrollRestoration />
          <TermsAndConditions />
        </>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <>
          <ScrollRestoration />
          <PrivacyPolicy />
        </>
      ),
    },
    {
      path: "/fitness-certificate",
      element: (
        <>
          <ScrollRestoration />
          <Fitness />
        </>
      ),
    },
    {
      path: "/fitness-certificate/form",
      element: (
        <>
          <ScrollRestoration />
          <SickForm />
        </>
      ),
    },
    {
      path: "/refund-cancellation-policy",
      element: (
        <>
          <ScrollRestoration />
          <Refund />
        </>
      ),
    },
  ],
  {
    basename: process.env.PUBLIC_URL || "/",
  }
);

export default Routers;
