import React from "react";
import { ReactComponent as SVG } from "../../assets/images/spinner.svg";

export default class Spinner extends React.PureComponent {
  render = () => {
    return (
      <>
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            zIndex: 1054,
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div style={{ position: "absolute", top: "50%", left: "48%" }}>
            <SVG style={{ height: "50%", width: "50%" }} />
          </div>
        </div>
      </>
    );
  };
}
