import "./Testimonials.scss";

import { TestimonialsCard } from "../homeComponents";

export const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <div className="testimonials-container-section1">
        <div className="testimonials-container-section1-wrapper">
          <div className="testimonials-container-section1-wrapper-text">
            Customer Testimonials
          </div>
        </div>
      </div>
      <div className="testimonials-container-section2">
        <div className="testimonials-container-section2-wrapper">
          <div className="testimonials-container-section2-wrapper-section">
            <TestimonialsCard
              reviewBy="Geff"
              review="Had a great experience in getting medical certificates with them. I had a emergency and they responded even at night 10PM!!! I never experienced such a great support. Highly recommended."
            />
          </div>
          <div className="testimonials-container-section2-wrapper-section">
            <TestimonialsCard
              reviewBy="Mayank Sharma"
              review="Had a great experience in getting medical certificates with them. I had a emergency and they responded even at night 10PM!!! I never experienced such a great support. Highly recommended."
            />
          </div>
          <div className="testimonials-container-section2-wrapper-section">
            <TestimonialsCard
              reviewBy="Sunil"
              review="Had a great experience in getting medical certificates with them. I had a emergency and they responded even at night 10PM!!! I never experienced such a great support. Highly recommended."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
