import { forwardRef } from "react";
import "./TextField.scss";
import { UseControllerProps, useController } from "react-hook-form";

type TextInputProps = {
  label?: string;
  type?: string;
  placeholder?: string;
};

export const TextField = forwardRef(
  (props: UseControllerProps & TextInputProps, ref) => {
    const { label, type = "text", placeholder } = props;
    const { field, fieldState } = useController(props);

    return (
      <div className="text-input">
        {label && <label className="text-input-label">{label}</label>}
        <input {...field} type={type} placeholder={placeholder} />
        {fieldState.error && (
          <div className="form-error">{fieldState.error.message}</div>
        )}
      </div>
    );
  }
);
