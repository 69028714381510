import { BottomBar, HomeIntro, NavBar } from "../components";

import { FloatingButtons } from "../components/common";

const Home = () => {
  return (
    <div>
      <NavBar />
      <HomeIntro />
      <FloatingButtons />
      <BottomBar />
    </div>
  );
};

export default Home;
