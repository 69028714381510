import {
  Flight,
  Work,
  Anchor,
  DirectionsRun,
  Videocam,
  CardTravel,
} from "@mui/icons-material";
import { Button, Testimonials, ThreeSteps, TrustUs } from "../common";
import "./FitnessIntro.scss";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

export const FitnessIntro = () => {
  const navigate = useNavigate();
  const types = [
    "✔️  All types of fitness certificates",
    "✔️  By certified Indian Doctors",
    "✔️  From the comfort of your home",
    "✔️  Get a soft copy within 30 minutes*",
    "✔️  For adoption, joining work, college or sports",
    "✔️  Abiding by NMC Guidelines",
  ];

  const renderTypes = () => {
    return (
      <div className="fitness-section1-types-text">
        {types.map((type, index) => {
          return <div key={index}>{type}</div>;
        })}
      </div>
    );
  };

  const renderFitnessTypes = (img: ReactElement, title: string) => {
    return (
      <div className="fitness-section2-each-fitness">
        {img}
        <div className="fitness-section2-each-fitness-title">{title}</div>
      </div>
    );
  };

  return (
    <div>
      <div className="fitness-section1">
        <div className="fitness-section1-title">
          {`Get a Medical Fitness\nCertificate Online`}
        </div>
        <div className="fitness-section1-types">{renderTypes()}</div>
        <Button
          title="Get Fitness Certificate"
          onClick={() => navigate("form")}
        />
      </div>
      <TrustUs />
      <div className="fitness-section2">
        <div className="fitness-section2-title">
          {`Type of Fitness\nCertificates we Offer`}
        </div>
        <div>
          {renderFitnessTypes(
            <Flight sx={{ fontSize: 60, color: "#0b582b" }} />,
            "Fit to Fly for Expecting\nMothers"
          )}
          {renderFitnessTypes(
            <Work sx={{ fontSize: 60, color: "#0b582b" }} />,
            "Fit to Work Medical\nCertificate"
          )}
          {renderFitnessTypes(
            <Anchor sx={{ fontSize: 60, color: "#0b582b" }} />,
            "Fit to Cruise Medical\nCertificate"
          )}
        </div>
        <div>
          {renderFitnessTypes(
            <DirectionsRun sx={{ fontSize: 60, color: "#0b582b" }} />,
            "Sports Medical Fitness\nCertificate"
          )}
          {renderFitnessTypes(
            <CardTravel sx={{ fontSize: 60, color: "#0b582b" }} />,
            "Youth Camp or Trip\nMedical Certificate"
          )}
          {renderFitnessTypes(
            <Videocam sx={{ fontSize: 60, color: "#0b582b" }} />,
            "Actor and Model Fit-to-Work\nCertificate"
          )}
        </div>
      </div>
      <div className="fitness-section2-button-wrapper">
        <Button
          title="Get Fitness Certificate"
          onClick={() => navigate("form")}
        />
      </div>
      {/* <-- Line --> */}
      <div className="fitness-section2-line-wrapper">
        <div className="fitness-section2-line-wrapper-line"></div>
      </div>

      <div className="fitness-section4">
        <div className="fitness-section4-title">
          {`Guide to Getting a\nFitness Medical\nCertificate`}
        </div>
        <div className="fitness-section4-desc">
          Are you looking for a fitness medical certificate online? Getting a
          fitness certificate doesn’t have to be a tedious task and can easily
          be done from the comfort of your home. If there is no specific format
          that is outlined by your organization go ahead and fill up your form
          for a medical fitness certificate. If you have a custom format, get in
          touch with us on Whatsapp or Email and share the format. Our team will
          be happy to have a look at it and suggest the necessary tests or
          details needed to help you fill-up the form and get your medical
          fitness certificate online.
        </div>
        <div className="fitness-section4-button-wrapper">
          <Button
            title="Get Fitness Certificate"
            onClick={() => navigate("form")}
          />
        </div>
      </div>

      <Testimonials />

      {/* <-- Three steps --> */}
      <ThreeSteps
        title="Get Your Medical Certificate In 3 Easy Steps!"
        onClick={() => navigate("form")}
        buttonTitle="Get Fitness Certificate"
      />
    </div>
  );
};
