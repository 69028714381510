import "./Navbar.scss";

import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";

import AppLogo from "../assets/images/app-logo.jpg";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";

export const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{ background: "#fff" }}
      className={`navbar ${isScrolled ? "navbar-scrolled" : "navbar-normal"}`}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ minHeight: "0 !important" }}>
          <Box
            sx={{
              flexGrow: 0,
              minHeight: "auto",
            }}
          >
            <img
              alt="app-logo"
              src={AppLogo}
              className={`app-logo ${
                isScrolled ? "logo-scrolled" : "logo-normal"
              }`}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", paddingLeft: "73px" },
            }}
          >
            <NavLink
              to="/home"
              className={({ isActive }) => {
                return isActive ? "active-tab" : "in-active-tab";
              }}
            >
              {"Home"}
            </NavLink>
            <NavLink
              to="/sick-certificate"
              className={({ isActive }) => {
                return isActive ? "active-tab" : "in-active-tab";
              }}
            >
              {"Sick Leave Certificate"}
            </NavLink>
            <NavLink
              to="/fitness-certificate"
              className={({ isActive }) => {
                return isActive ? "active-tab" : "in-active-tab";
              }}
            >
              Fitness Certificate
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive }) => {
                return isActive ? "active-tab" : "in-active-tab";
              }}
            >
              About Us
            </NavLink>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          ></Box>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="tabs"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem>
                <NavLink
                  to="/"
                  end
                  className={({ isActive }) => {
                    return isActive ? "active-tab" : "in-active-tab";
                  }}
                >
                  {"Home"}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/sick-certificate"
                  className={({ isActive }) => {
                    return isActive ? "active-tab" : "in-active-tab";
                  }}
                >
                  {"Sick Leave Certificate"}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/fitness-certificate"
                  className={({ isActive }) => {
                    return isActive ? "active-tab" : "in-active-tab";
                  }}
                >
                  Fitness Certificate
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/about-us"
                  className={({ isActive }) => {
                    return isActive ? "active-tab" : "in-active-tab";
                  }}
                >
                  About Us
                </NavLink>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
