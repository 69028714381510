import { forwardRef } from "react";
import "./HCSelect.scss";
import { UseControllerProps, useController } from "react-hook-form";
import Select from "react-select";

type HCSelectProps = {
  options: { label: string; value: string }[];
  label?: string;
};

export const HCSelect = forwardRef(
  (props: UseControllerProps & HCSelectProps, ref) => {
    const { options, label } = props;
    const { field, fieldState } = useController(props);
    return (
      <div className="hc-select">
        {label && <label className="hc-select-label">{label}</label>}
        <div className="hc-select-select-wrapper">
          <Select
            {...field}
            options={options}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                padding: "7px",
              }),
            }}
          />
          {fieldState.error && (
            <div className="form-error">{fieldState.error.message}</div>
          )}
        </div>
      </div>
    );
  }
);
