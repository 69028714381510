import { NavBar } from "../components";
import { FloatingButtons } from "../components/common";
import { TermsAndConditionsIntro } from "../components/termsAndConditionsComponents";

const TermsAndConditions = () => {
  return (
    <div>
      <NavBar />
      <TermsAndConditionsIntro />
      <FloatingButtons />
    </div>
  );
};

export default TermsAndConditions;
