import "./TestimonialsCard.scss";
import FormatQuoteSharpIcon from "@mui/icons-material/FormatQuoteSharp";

export const TestimonialsCard = ({
  review,
  reviewBy,
}: {
  review: string;
  reviewBy: string;
}) => {
  return (
    <div className="testimonial-card">
      <div className="review-text">
        <FormatQuoteSharpIcon
          sx={{ fontSize: 20, color: "#0b582b", transform: "scaleX(-1)" }}
        />
        {review}
        <FormatQuoteSharpIcon sx={{ fontSize: 20, color: "#0b582b" }} />
      </div>
      <div className="review-by">{reviewBy}</div>
    </div>
  );
};
