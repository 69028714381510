import { forwardRef } from "react";
import "./RadioField.scss";
import { UseControllerProps, useController } from "react-hook-form";

type RadioInputProps = {
  label?: string;
  options: { id: string; value: string | number; name: string }[];
};

export const RadioField = forwardRef(
  (props: UseControllerProps & RadioInputProps, ref) => {
    const { label, options } = props;
    const { field, fieldState } = useController(props);

    return (
      <div {...field} className="radio-input">
        {label && (
          <label className="radio-input-label">
            {label}
            <em>*</em>
          </label>
        )}
        {options.map(({ value, name }, index) => {
          return (
            <div className="radio-input-field-wrapper" key={index}>
              <input type="radio" value={value} name={field.name} />
              <div className="radio-input-options-label">{name}</div>
            </div>
          );
        })}
        {fieldState.error && (
          <div className="radio-input-form-error">
            {fieldState.error.message}
          </div>
        )}
      </div>
    );
  }
);
